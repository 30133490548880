import SecurityAPI from '@/api/security'
import token from '@/api/token'

const AUTHENTICATING = 'AUTHENTICATING'
const AUTHENTICATING_SUCCESS = 'AUTHENTICATING_SUCCESS'
const AUTHENTICATING_ERROR = 'AUTHENTICATING_ERROR'
const LOGOUT = 'LOGOUT'
const INITIALISE_TOKEN = 'INITIALISE_TOKEN'
const CLEAR_TOKEN = 'CLEAR_TOKEN'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    isAuthenticated: false,
    token: null
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    hasError (state) {
      return state.error !== null
    },
    error (state) {
      return state.error
    },
    isAuthenticated () {
      return token.validate()
    },
    hasRole (state) {
      return role => {
        return state.user.roles.indexOf(role) !== -1
      }
    }
  },
  mutations: {
    [AUTHENTICATING] (state) {
      state.isLoading = true
      state.error = null
      state.isAuthenticated = false
      state.token = null
    },
    [AUTHENTICATING_SUCCESS] (state, data) {
      state.isLoading = false
      state.error = null
      state.isAuthenticated = true
      state.token = data.token
    },
    [AUTHENTICATING_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
      state.isAuthenticated = false
      state.token = null
    },
    [LOGOUT] (state) {
      state.isLoading = false
      state.error = null
      state.isAuthenticated = false
      state.token = null
    },
    [INITIALISE_TOKEN] (state, token) {
      state.isLoading = false
      state.error = null
      state.isAuthenticated = true
      state.token = token
    },
    [CLEAR_TOKEN] (state) {
      state.isLoading = false
      state.error = null
      state.isAuthenticated = false
      state.token = null
    }
  },
  actions: {
    async login ({ commit }, payload) {
      commit(AUTHENTICATING)
      try {
        let response = await SecurityAPI.login(payload.login, payload.password)
        token.set(response.data.token)

        commit(AUTHENTICATING_SUCCESS, response.data)
        return response.data
      } catch (error) {
        commit(AUTHENTICATING_ERROR, error)
        return null
      }
    },
    logout ({ commit }) {
      token.clear()
      commit(LOGOUT)
    },
    loadToken ({ commit }) {
      if (token.get()) {
        if (token.validate()) {
          commit(INITIALISE_TOKEN, token.get())
        } else {
          token.clear()
          commit(CLEAR_TOKEN)
        }
      }
    }
  }
}
