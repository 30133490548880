import Vue from 'vue'
import Vuex from 'vuex'
import security from './modules/security'
import common from './modules/common'
import socket from './modules/socket'
import socketPlugin from './plugins/socket';

Vue.use(Vuex)

let store = new Vuex.Store({
  modules: {
    security,
    common,
    socket
  },
  plugins: [socketPlugin()]
})

export default store
