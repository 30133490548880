import Vue from 'vue'
import Router from 'vue-router'
import token from '@/api/token'

const TheContainer = () => import('@/containers/TheContainer')
// Views
const Dashboard = () => import('@/views/Dashboard')
const Customers = () => import('@/views/customers/List')
const CustomerInfo = () => import('@/views/customers/Info')
const Products = () => import('@/views/products/List')
const Sales = () => import('@/views/sales/List')
const Exports = () => import('@/views/exports/List')

const Login = () => import('@/views/Login')
Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token.validate()) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'customers',
          name: 'Customers',
          component: Customers
        },
        {
          path: 'customer-view/:id',
          name: 'CustomerInfo',
          component: CustomerInfo
        },
        {
          path: 'products',
          name: 'Products',
          component: Products
        },
        {
          path: 'sales',
          name: 'Sales',
          component: Sales
        },
        {
          path: 'exports',
          name: 'Exports',
          component: Exports
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
}

export default router
