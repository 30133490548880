import axios from 'axios'
import token from './token'
import router from '../router'
import store from './../store'

let baseUrl = ''

if (process.env.NODE_ENV === 'development') {
  baseUrl = (process.env.VUE_APP_API_SERVER !== undefined) ? process.env.VUE_APP_API_SERVER : '/api'
} else if (process.env.NODE_ENV === 'production') {
  baseUrl = '/api'
}

let api = axios.create({
  baseURL: baseUrl
})

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
api.interceptors.request.use(
  (config) => {
    let tokenData = token.get()

    if (tokenData) {
      config.headers['Authorization'] = `Bearer ${tokenData}`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use((response) => {
  return response
}, (err) => {
  return new Promise(() => {
    if (err.response.status === 401) {
      store.dispatch('security/logout')
      router.push({ path: '/login' })
    } else if ((err.response.status === 500) && (process.env.NODE_ENV === 'development')) {
      document.open()
      document.write(err.response.data)
      document.close()
    }
    throw err
  })
})

export default api
