//noinspection ES6UnusedImports

import {
// cib icons
} from '@coreui/icons'
import {
// cif icons
} from '@coreui/icons'
import {
    cilCart,
    cilGift,
    cilGroup,
    cilSpeedometer,
    cilArrowTop,
    cilArrowBottom,
    cilSwapVertical,
    cilLockLocked,
    cilUser,
    cilMoney,
    cilCreditCard,
    cilGraph,
    cilX,
    cilCloudDownload,
    cilCaretBottom,
    cilCaretTop,
    cilWifiSignal0,
    cilWifiSignalOff
} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
      cilGroup,
      cilGift,
      cilCart,
      cilSpeedometer,
      cilArrowTop,
      cilArrowBottom,
      cilSwapVertical,
      cilLockLocked,
      cilUser,
      cilMoney,
      cilCreditCard,
      cilGraph,
      cilX,
      cilCloudDownload,
      cilCaretBottom,
      cilCaretTop,
      cilWifiSignal0,
      cilWifiSignalOff
  },
  {
      // cif icons
  },
  {
      // cib icons
  }
)
