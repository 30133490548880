const state = {
    connected: false,
    error: null,
    messages: [],
    limit: 15
};

const getters = {
    getMessages: state => state.messages,
    connected: state => state.connected
};

const actions = {
    addMessage({ commit }, message) {
        commit('ADD_MESSAGE', message);
    },
    connectionOpened({ commit }) {
        commit('SET_CONNECTION', true);
    },
    connectionClosed({ commit }) {
        commit('SET_CONNECTION', false);
    },
    connectionError({ commit }, error) {
        commit('SET_ERROR', error);
        commit('SET_CONNECTION', false);
    }
};

const mutations = {
    ADD_MESSAGE(state, message) {
        while (state.messages.length >= state.limit) {
            state.messages.shift();
        }
        state.messages.push(message);
    },
    SET_CONNECTION(state, message) {
        state.connected = message;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
