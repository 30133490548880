export default function createWebSocketPlugin() {
    return store => {
        let url = ''

        if (process.env.NODE_ENV === 'development') {
            url = (process.env.VUE_APP_SOCKET_SERVER !== undefined) ? process.env.VUE_APP_SOCKET_SERVER : ('ws://' + location.host + '/ws')
        } else if (process.env.NODE_ENV === 'production') {
            url = 'ws://' + location.host + '/ws'
        }

        let socket = new WebSocket(url);

        socket.onopen = function() {
            store.dispatch('socket/connectionOpened')
            console.info('Socket opened on: ' + process.env.VUE_APP_SOCKET_SERVER)
        };

        socket.onmessage = function(event) {
            store.dispatch('socket/addMessage', JSON.parse(event.data))
        };

        socket.onclose = function() {
            store.dispatch('socket/connectionClosed')
            console.info('Socket closed')
        };

        socket.onerror = function(error) {
            store.dispatch('socket/connectionError', error)
            console.info('Socket error', error)
        };
    };
}
