const TOGGLE_SIDEBAR_MOBILE = 'TOGGLE_SIDEBAR_MOBILE'
const TOGGLE_SIDEBAR_DESKTOP = 'TOGGLE_SIDEBAR_DESKTOP'

export default {
  namespaced: true,
  state: {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    breadcrumbs: []
  },
  getters: {
    sidebarShow: (s) => s.sidebarShow,
    sidebarMinimize: (s) => s.sidebarMinimize,
    breadcrumbs: (s) => s.breadcrumbs
  },
  mutations: {
    [TOGGLE_SIDEBAR_MOBILE] (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    [TOGGLE_SIDEBAR_DESKTOP] (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    set (state, [variable, value]) {
      state[variable] = value
    }
  },
  actions: {}
}
