import api from './api'

export default {
  login (login, password) {
    return api.post('/login', {
      username: login,
      password: password
    })
  },
  user () {
    return api.get('/user')
  }
}
